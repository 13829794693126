import Vue from 'vue'
import VueRouter from 'vue-router';
import App from './App.vue'
import './assets/css/app.css'
import 'animate.css';
Vue.config.productionTip = false
Vue.use(VueRouter);

const routes = [
  {
    path:'/:key',
    name: 'Invitation',
    component: () => import('./App.vue')
  }
];
const router = new VueRouter({
  routes,
  base: 'https://boda-serrano-rodriguez.website',
  mode: 'history',
});

new Vue({
  render: (h) => h(App),
  router,
}).$mount('#app')
