<script>
import moment from 'moment'
//import { ref } from 'vue';
const accountSid = 'AC6e18670ebdb21fed26fb3312ebbe7726';
const authToken = '2957edfe27b9d2a60333a141d4c1eba1';
// const client = require('twilio')(accountSid, authToken);]
import axios from 'axios';
export default {
    data() {
        return {
            dateTarget: moment('2023-12-06T17:00:00'),
            timeLeft: null,
            userAgent: false,
            dark: false,
            selectedInvitado: null,
            invitados: {
                cecilia: {
                    name: 'Tía Ceci',
                    assistants: 4,
                },
                abuelos_mama: {
                    name: 'Abuelos',
                    assistants: 5,
                },
                nelson: {
                    name: 'Padres del novio',
                    assistants: 2,
                },
                karla: {
                    name: 'Karla',
                    assistants: 2,
                },
                salvador: {
                    name: 'Salvador Serrano',
                    assistants: 6,
                },
                magda: {
                    name: 'Tia Magda',
                    assistants: 4,
                },
                abuela_rosa: {
                    name: 'Abuela de la novia',
                    assistants: 4,
                },
                didier: {
                    name: 'Didier Alvares',
                    assistants: 3,
                },
                jesus: {
                    name: 'Jesús',
                    assistants: 2,
                },
                rigo: {
                    name: 'Tío Rigo',
                    assistants: 2,
                },
                antonio: {
                    name: 'Tío Antonio',
                    assistants: 2,
                },
                pablo: {
                    name: 'Pablo Rodriguez',
                    assistants: 6,
                },
            },
            optionsConfirmation: ['SI ASISTIRE', 'NO ASISTIRE'],
            form: {
                confirmation: '',
                assistants: '',
                messages: '',
            }
        }
    },
    computed: {
        days() {
            if(this.timeLeft){
                return this.timeLeft.days();
            }
        },
        hours(){
            if(this.timeLeft){
                return this.timeLeft.hours();
            }
        },
        minutes(){
            if(this.timeLeft){
                return this.timeLeft.minutes();
            }
        },
        seconds() {
            if(this.timeLeft){
                return this.timeLeft.seconds();
            }
        },
        styleLogo() {
            return this.userAgent && !this.dark ? 'text-color' : 'text-color';
        },
        styleDark() {
            return this.userAgent && !this.dark ? 'fondo-none' : '';
        }
    },
    mounted(){
        this.updateTime();
        setInterval(this.updateTime, 1000);
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            // El sistema está en modo oscuro
            this.dark = true;
            console.log('Modo oscuro');
        } else {
            // El sistema está en modo claro
            console.log('Modo claro');
        }
        if (navigator.userAgent.includes("Samsung")) {
            // El dispositivo es un Samsung
            this.userAgent = true;
            console.log('Es un dispositivo Samsung');
        } else {
            // No es un dispositivo Samsung
            console.log('No es un dispositivo Samsung');
        }
        setTimeout(() => {
            const key = this.$route.params.key;
            console.log(this.invitados[key]);
            this.selectedInvitado = this.invitados[key];
        }, 200);
    },
    methods: {
        updateTime() {
            const currentDate = moment();
            this.timeLeft = moment.duration(this.dateTarget.diff(currentDate));
        },
        range(start, end) {
            // Crea un rango numérico desde 'start' hasta 'end'
            return Array.from({ length: end - start + 1 }, (_, i) => start + i);
        },
        sendMessage(){
            
            const body = `Hola estimados novios\nSoy: ${this.selectedInvitado.name} \nConfirmación de mi invitación: ${this.form.confirmation}\nAsisteremos: ${this.form.assistants}\n${this.form.messages}`
            const postData = new URLSearchParams();
            postData.append('To', 'whatsapp:+50375119202');
            postData.append('From', 'whatsapp:+14155238886');
            postData.append('Body', body);
            // Define las opciones de la solicitud
            const axiosConfig = {
            method: 'post',
            url: 'https://api.twilio.com/2010-04-01/Accounts/AC6e18670ebdb21fed26fb3312ebbe7726/Messages.json',
            data: postData,
            auth: {
                username: 'AC6e18670ebdb21fed26fb3312ebbe7726',
                password: '2957edfe27b9d2a60333a141d4c1eba1'
            },
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
            };
            axios(axiosConfig)
                .then(response => {
                    console.log(response.data);
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }
}
</script>

<template>
    <div>
        <div class="invitation-logo fondo8 flex justify-center flex-col" :class="styleDark">
            <div class="text-center flex items-center w-full justify-center">
                <img alt="Invitation logo" class="logo w-[20%] md:w-[10%] animate__animated animate__heartBeat animate__repeat" src="../assets/logo.png">
            </div>
            <h1 class="title-logo text-5xl mt-[-10px] animate__animated animate__backInLeft animate__delay-0.25s tracking-wider" :class="styleLogo">Rosa & Jasson</h1>
            <span style="letter-spacing:0em;" class="text-date text-2xl" :class="styleLogo" v-if="selectedInvitado">06 • 12 • 2023</span>
        </div>
        <div class="invitation-crono fondo5 flex justify-center flex-col" :class="styleDark">
            <div class="text-center flex mt-3 items-center w-full justify-center">
                <div class="line"></div>
            </div>
            <h1 class="title-logo text-4xl mt-2 animate__animated animate__tada animate__delay-0.25s" :class="styleLogo">Nuestra Boda
            </h1>
            <div class="cronometro animate__animated animate__pulse" v-if="selectedInvitado">
                <div class="tiempo">
                    <div class="dia">{{ days }} <span class="text-sm">Días</span></div>
                    <div> : </div>
                    <div class="hora">{{ hours }} <span class="text-sm">Horas</span></div>
                    <div> : </div>
                    <div class="minuto">{{ minutes }} <span class="text-sm">Min</span></div>
                    <div> : </div>
                    <div class="segundo">{{ seconds }} <span class="text-sm">Seg</span></div>
                </div>
            </div>
        </div>
        <div class="invitation-phrase fondo7 flex justify-center flex-col" :class="styleDark" v-if="selectedInvitado">
            <div class="text-center flex mt-3 items-center w-full justify-center">
                <p class="text-3xl text-center w-4/5 md:w-1/3 text-color text-font2 m-4 tracking-wider" :class="styleLogo">Que cada amanecer nos encuentre más cerca, más fuertes y más enamorados que nunca, superando juntos las pruebas de la vida!.</p>
            </div>
        </div>
        <div class="invitation-ceremonia fondo4 flex justify-center flex-col" :class="styleDark" v-if="selectedInvitado">
            <div class="text-center flex flex-col mt-3 items-center w-full justify-center">
                <img src="../assets/anillos.png" class="w-28" />
                <p class="text-3xl text-center w-4/5 md:w-1/2 text-color text-font2 m-1" :class="styleLogo">Ceremonia Civil</p>
                <p class="text-3xl text-center w-4/5 md:w-1/2 text-color text-font2 m-1" :class="styleLogo">5:00 pm</p>
                <p class="text-3xl text-center w-4/5 md:w-1/2 text-color text-font2 m-1" :class="styleLogo">Bellanova - Jardin</p>
                <p class="text-2xl text-center w-4/5 md:w-1/3 text-color text-font2 m-1 tracking-wider" :class="styleLogo">Carretera panamericana, salida a la Unión, entrada a col. Carrillo #1 , San Miguel, El Salvador</p>
                <a class="btn-mapa" href="https://maps.app.goo.gl/pQd2oNNiYk8A8Wr1A" target="_blank">
                    Ver Mapa
                </a>
            </div>
        </div>
        <div class="invitation-confirma fondo2 flex justify-center flex-col" :class="styleDark" v-if="selectedInvitado">
            <div class="text-center flex flex-col mt-3 items-center w-full justify-center">
                <img src="../assets/confirma.png" class="w-52" />
                <p class="text-4xl md:text-5xl text-center w-4/5 md:w-1/2 text-color text-font2 m-1" :class="styleLogo">Confirma tu Asistencia</p>
                <p class="text-2xl md:text-3xl text-center w-4/5 md:w-1/2 text-color text-font2 m-1 tracking-wider" :class="styleLogo">Hola {{ selectedInvitado.name }}! Sería tan amable de confirmar su asistencia antes del 24 de noviembre.</p>
                <div class="w-3/4 md:w-1/2 m-2">
                    <form>
                        <div class="flex flex-row gap-4 mb-4">
                            <div class="w-1/2">
                                <span class="text-left text-font text-color">¿Asistirás a mi boda?</span>
                                <select type="text" v-model="form.confirmation" class="form-control border rounded text-font text-color w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="" >
                                    <option v-for="n in optionsConfirmation" :key="n" :value="n">{{ n }}</option>
                                </select>
                            </div>
                            <div class="w-1/2">
                                <span class="text-left text-font text-color">Número de asistentes</span>
                                <select type="text" v-model="form.assistants" class="form-control border rounded text-font text-color w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Número de asistentes" >
                                    <option v-for="(n, index) in range(1, selectedInvitado.assistants)" :key="index" :value="n">{{ n }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="flex flex-row gap-4">
                            <div class="w-full">
                                <textarea v-model="form.messages" class="form-control border rounded min-h-[100px] text-font text-color w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Mensaje para los novios" >
                                </textarea>
                            </div>
                        </div>
                        <div class="flex flex-row gap-4 justify-end">
                            <button type="button" class="btn-mapa text-font" @click="sendMessage()">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="invitation-confirma fondo2 flex justify-center flex-col" :class="styleDark" v-else>
            <div class="text-center flex flex-col mt-3 items-center w-full justify-center">
                <img src="../assets/confirma.png" class="w-52" />
                <p class="text-5xl text-center w-4/5 md:w-1/3 text-color text-font2 m-4 tracking-wider" :class="styleLogo">Agradezco que quiera formar parte de nuestra boda, pero lamentablemente no está invitado en esta ocasión.!.</p>
            </div>
        </div>
    </div>
</template>

<style scoped>
.invitation-logo{
    background-repeat: no-repeat;
    width: 100%;
    max-height: auto;
}
.invitation-phrase{
    background-repeat: no-repeat;
    width: 100%;
    max-height: auto;
}
.invitation-crono{
    background-repeat: no-repeat;
    width: 100%;
    max-height: auto;
}
.invitation-confirma{
    background-repeat: no-repeat;
    width: 100%;
    max-height: auto;
}
.invitation-ceremonia{
    background-repeat: no-repeat;
    width: 100%;
    max-height: auto;
}

.fondo1{
    background-image: url('../assets/fondo1.png');
    background-size: 100% 100%;
}

.fondo2{
    background-image: url('../assets/fondo2.png');
    background-size: 100% 100%;
}
.fondo3{
    background-image: url('../assets/fondo3.png');
    background-size: 100% 100%;
}
.fondo4{
    background-image: url('../assets/fondo4.png');
    background-size: 100% 100%;
}
.fondo5{
    background-image: url('../assets/fondo5.png');
    background-size: 100% 100%;
}
.fondo6{
    background-image: url('../assets/fondo6.png');
    background-size: 100% 100%;
}
.fondo7{
    background-image: url('../assets/fondo7.png');
    background-size: 100% 100%;
}
.fondo8{
    background-image: url('../assets/fondo8.png');
    background-size: 100% 100%;
}
.fondo-none{
    background-image: none !important;
    background-color: white;
    background-size: 100% 100%;
}

.form-control{
    border: 1px solid rgba(#C49988, 0.4);
    width: 100%;
    padding: 10px;
}

.btn-mapa{
    text-decoration: none;
    cursor: pointer;
    background-color: rgb(128, 84, 30);
    padding: 15px 25px;
    border-radius: 10px;
    color: white;
    margin: 15px;
}
.btn-mapa:hover{
    background-color: rgb(56, 31, 1);
    color: white;
}

.text-color{
    color: #C49988;
}
.text-font{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.text-font2{
    font-family: 'Great Vibes', regular;
}
.line{
    background-color: #C49988;
    width: 2px;
    height: 50px;
    opacity: 0.5;
}

.logo{
    margin-top: 1em;
}

.title-logo{
    color: #C49988;
    font-family: 'Great Vibes', regular;
    margin-bottom: 5px !important;
}

.text-date{
    color: #C49988;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.cronometro {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    max-width: 350px;
    color: white;
    border: 1px solid #C49988;
    margin: 0 auto;
    border-radius: 20px;
    background-color: #C49988;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}



.text-color-dark{
    color: #ffffff !important;
}

.cronometro-dark {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    max-width: 350px;
    color: white;
    border: 1px solid #C49988;
    margin: 0 auto;
    border-radius: 20px;
    background-color: #f0c8b8;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.tiempo {
    display: flex;
    align-items: center;
}

.dia,
.hora,
.minuto,
.segundo {
    margin: 0 2px;
    display: flex;
    flex-direction: column;
    margin: 15px;
}

.dia:before,
.hora:before,
.minuto:before,
.segundo:before {
    content: "";
}

.dia:after,
.hora:after,
.minuto:after,
.segundo:after {
    content: "";
}

span {
    font-weight: bold;
}
</style>